:root {
  --main-color: #006db5;
  --sub-color: #d9d9d9;
  --black-color: #17171b;
  --white-color: #ffffff;
  --blue-001: #0e1427;
  --red-001: #db2931;
  --grey_001: #666;
}

html {
  font-size: 16px;
}

body {
  -moz-osx-font-smoothing: grayscale;
  color: var(--black-color);
}

* {
  box-sizing: border-box;
  outline: none;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, system-ui, Roboto,
    "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR",
    "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    sans-serif;
  touch-action: pan-y;
}

blockquote,
body,
dd,
dl,
dt,
fieldset,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
html,
iframe,
legend,
li,
ol,
p,
pre,
textarea,
ul,
button {
  margin: 0;
  padding: 0;
}

a,
a:link,
a:visited {
  text-decoration: none;
}
a:active,
a:hover {
  text-decoration: none;
}

button {
  border: none;
  background: none;
}

textarea {
  resize: none;
}

iframe {
  background: transparent;
}
ul,
ol {
  list-style: none;
  padding: 0;
}

select {
  -webkit-appearance: none; /* 크롬 화살표 없애기 */
  -moz-appearance: none; /* 파이어폭스 화살표 없애기 */
  appearance: none; /* 화살표 없애기 */
  background: url("assets/images/select_arrow.png") no-repeat right 4px center
    white;
}

.custom-section {
  height: auto;
}

.custom-footer-slide {
  height: auto !important;
}
